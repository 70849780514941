import http from "../http-common";

class ClassInvoiceService {
  fetchById({ invoiceId }) {
    return http.get(`invoices/${invoiceId}`);
  }

  getPDF({ invoiceId }) {
    return http.get(`invoices/${invoiceId}/document`, { responseType: "blob" });
  }
}

const InvoiceDataService = new ClassInvoiceService();
export default InvoiceDataService;
