import { useLocation } from "react-router-dom";

import { FlexBox } from "components/flex-box";
import { Span } from "components/Typography"; // CUSTOM ICON COMPONENT

import { MainContainer, StyledNavLink } from "./styles";

const Navigation = ({projects}) => {
  const pathname = useLocation().pathname;
  return (
    <MainContainer sx={{paddingTop: '15px'}}>
      <StyledNavLink
        to="/"
        key="all"
        isCurrentPath={pathname === "/"}
      >
        <FlexBox alignItems="center" gap={1}>
          <Span>Übersicht</Span>
        </FlexBox>
      </StyledNavLink>
      {projects.length > 0 && projects.map(({ id, displayName, jiraKey }) => (
            <StyledNavLink
              to={`/projects/${id}`}
              key={id}
              isCurrentPath={pathname === `/overview/${id}`}
            >
              <FlexBox alignItems="center" gap={1}>
                <Span>{displayName}</Span>
              </FlexBox>
            </StyledNavLink>
      ))}
    </MainContainer>
  );
};

export default Navigation;
