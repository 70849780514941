import { Edit, Delete } from "@mui/icons-material";
import {
  StyledIconButton,
  StyledTableCell,
  StyledTableRow,
} from "pages-sections/StyledComponents";
import useProjectActions from "hooks/useProjectActions";
import ProjectDeleteButton from "./ProjectDeleteButton";
import ProjectActiveSwitch from "./ProjectActiveSwitch";

// ========================================================
const ProjectRow = ({ project }) => {

  return (
    <>
      <StyledTableRow tabIndex={-1} role="checkbox">
        <StyledTableCell align="left">{project?.displayName}</StyledTableCell>
        <StyledTableCell align="left">{project?.customerId}</StyledTableCell>
        <StyledTableCell align="left">{project?.jiraKey}</StyledTableCell>
        <StyledTableCell align="center">{project?.sevdeskID}</StyledTableCell>
        <StyledTableCell align="center">
          <ProjectActiveSwitch project={project} />
        </StyledTableCell>
        <StyledTableCell align="center">
          <StyledIconButton href={`/administration/projects/${project.id}`}>
            <Edit />
          </StyledIconButton>
          <ProjectDeleteButton project={project} />
        </StyledTableCell>
      </StyledTableRow>
    </>
  );
};

export default ProjectRow;
