import { useAlertMessageStore, useLoadingStore } from "store/store";
import SevDeskDataService from "services/sevdesk.service";
import InvoiceDataService from "services/invoice.service";

// ================================================================

// ================================================================

// ================================================================

// ================================================================

const useInvoice = (props) => {
  const getPDF = (invoice) => {
    useLoadingStore.setState({ isLoading: true });
    InvoiceDataService.getPDF({ invoiceId: invoice.id }).then((response) => {
      useLoadingStore.setState({ isLoading: false });
      const filename = response.headers["content-disposition"]
        .split('filename="')[1]
        .split('"')[0];
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", filename);
      document.body.appendChild(link);
      link.click();
    });
  };

  return {
    getPDF,
  };
};
export default useInvoice;
