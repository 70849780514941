import { Link as RouterLink, useLocation } from "react-router-dom";
import Card from "@mui/material/Card";
import Stack from "@mui/material/Stack";
import { styled } from "@mui/material/styles";
import { FlexBetween, FlexBox } from "components/flex-box"; // CUSTOM UTILS LIBRARY FUNCTION
import { Paragraph } from "components/Typography";
import format from "date-fns/format"; // GLOBAL CUSTOM COMPONENTS
import Scrollbar from "components/Scrollbar";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TablePagination from "components/data-table/TablePagination";
import useMuiTable from "hooks/useMuiTable";
import { useEffect } from "react";

const statusColor = {
  locked: "#EBD07F",
  paid: "#D2E7D4",
};

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  padding: "5px",
  borderBottom: "none",
}));

// ==============================================================
const InvoicesOverview = ({ invoices, title, invoiceType }) => {
  const location = useLocation();
  const { page, rowsPerPage, filteredList, handleChangePage } = useMuiTable({
    listData: invoices,
  });

  useEffect(() => {
    handleChangePage(undefined, 1)
  }, [location])


  return (
    <Card
      sx={{
        p: 0,
        mb: "30px",
      }}
    >
      <FlexBetween px={3} py={2} flexWrap="wrap" bgcolor="grey.200">
        <FlexBox gap={1} alignItems="center">
          <Paragraph color="grey.600">Anzahl {title}: </Paragraph>
          <Paragraph>{invoices.length}</Paragraph>
        </FlexBox>
      </FlexBetween>
      {invoices.length > 0 ? (
        <>
          <Scrollbar>
            <TableContainer
              sx={{
                minWidth: 900,
              }}
            >
              <Table
                sx={
                  invoiceType === "SR" && {
                    "& tr:nth-child(4n+3)": { backgroundColor: "#d9d9d9" },
                    "& tr:nth-child(4n+4)": { backgroundColor: "#d9d9d9" },
                  }
                }
              >
                <TableHead>
                  <TableRow>
                    <TableCell sx={{ fontWeight: "bold" }}>Nr.</TableCell>
                    <TableCell sx={{ fontWeight: "bold" }}>Datum</TableCell>
                    <TableCell sx={{ fontWeight: "bold" }}>Kunde</TableCell>
                    <TableCell sx={{ fontWeight: "bold" }}>Summe</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {filteredList.map((invoice) => (
                    <>
                      <TableRow
                        component={RouterLink}
                        to={`/invoices/${invoice.id}`}
                        sx={
                          invoiceType === "RE"
                            ? {
                                backgroundColor: statusColor[invoice.status],
                                borderBottom: "1px solid rgba(0, 0, 0, 1)",
                              }
                            : {
                                borderBottom:
                                  "1px solid rgba(224, 224, 224, 1)",
                              }
                        }
                      >
                        <StyledTableCell>
                          {invoice.number !== "" ? invoice.number : invoice.id}
                        </StyledTableCell>
                        <StyledTableCell>
                          {format(new Date(invoice.date), "dd.MM.yyyy")}
                        </StyledTableCell>
                        <StyledTableCell>
                          {invoice.customerData.name}
                        </StyledTableCell>
                        <StyledTableCell>
                          {invoice.totalNet?.toLocaleString(undefined, {
                            minimumFractionDigits: 2,
                          })}
                          €
                        </StyledTableCell>
                      </TableRow>
                      {invoice.cancellation && (
                        <TableRow>
                          <StyledTableCell>
                            {invoice.cancellation.number}
                          </StyledTableCell>
                          <StyledTableCell>
                            {format(new Date(invoice.date), "dd.MM.yyyy")}
                          </StyledTableCell>
                          <StyledTableCell>
                            {invoice.customerData.name}
                          </StyledTableCell>
                          <StyledTableCell>
                            {invoice.cancellation.totalNet?.toLocaleString(
                              undefined,
                              { minimumFractionDigits: 2 }
                            )}
                            €
                          </StyledTableCell>
                        </TableRow>
                      )}
                    </>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Scrollbar>
          <Stack alignItems="center" my={4}>
            <TablePagination
              onChange={handleChangePage}
              count={Math.ceil(invoices.length / rowsPerPage)}
              page={page+1}
            />
          </Stack>
        </>
      ) : (
        <FlexBetween px={3} py={2} flexWrap="wrap">
          <FlexBox gap={1} alignItems="center">
            <Paragraph color="grey.600">
              Es wurden keine {title} gefunden
            </Paragraph>
          </FlexBox>
        </FlexBetween>
      )}
    </Card>
  );
};

export default InvoicesOverview;
