import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import Stack from "@mui/material/Stack";
import { styled } from "@mui/material/styles";
import { FlexBetween, FlexBox } from "components/flex-box"; // CUSTOM UTILS LIBRARY FUNCTION
import { Paragraph } from "components/Typography";
import IconButton from "@mui/material/IconButton";
import Scrollbar from "components/Scrollbar";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TablePagination from "components/data-table/TablePagination";
import Tooltip from "@mui/material/Tooltip";
import DescriptionIcon from "@mui/icons-material/Description";
import PendingActionsIcon from "@mui/icons-material/PendingActions";
import useInvoice from "hooks/useInvoice";
import useTimesheet from "hooks/useTimesheet";
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import ProjectDataService from "services/project.service";
import InvoiceDataService from "services/invoice.service";
import { Button, Container, Grid, Typography } from "@mui/material";
import { DashboardLayout } from "components/layouts/dashboard";
import { useLoadingStore } from "store/store";

import { Navigation } from "components/layouts/dashboard";
import Header from "pages-sections/header";
import { format, isValid, parseISO } from "date-fns";

const statusColor = {
  locked: "#EBD07F",
  paid: "#D2E7D4",
};

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  padding: "5px",
  borderBottom: "none",
}));

// ==============================================================
const InvoiceDetails = () => {
  const [invoice, setInvoice] = useState({});
  const [projects, setProjects] = useState([]);
  const { getPDF } = useInvoice();
  const { getPDFProject } = useTimesheet();
  const { invoiceId } = useParams();

  useEffect(() => {
    useLoadingStore.setState({ isLoading: true });
    ProjectDataService.getAll(true).then((response) => {
      setProjects(response.data.projects);
    });
    InvoiceDataService.fetchById({ invoiceId })
      .then((response) => {
        setInvoice(response.data.invoice);
        useLoadingStore.setState({ isLoading: false });
      })
      .catch((error) => {
        useLoadingStore.setState({ isLoading: false });
      });
  }, []);

  return (
    <Container
      maxWidth="xxl"
      sx={{
        my: 4,
        px: { xs: 0, md: 2 },
      }}
    >
      <Grid container spacing={3}>
        <Grid
          item
          lg={3}
          xs={12}
          sx={{
            display: {
              xs: "none",
              sm: "none",
              md: "block",
            },
          }}
        >
          <Navigation projects={projects} />
        </Grid>

        <Grid item lg={9} xs={12}>
          <Header
            title={invoice.title || invoice.id || "Rechnung"}
            section="dashboard"
            navigationData={projects}
          />
          <Card
            sx={{
              p: 0,
              mb: "15px",
            }}
          >
            <FlexBetween px={3} py={2} flexWrap="wrap">
              <FlexBox gap={1} alignItems="center">
                <Button
                  onClick={() => getPDF(invoice)}
                  color="info"
                  variant="contained"
                  sx={{
                    minHeight: 44,
                  }}
                >
                  PDF anzeigen
                </Button>

                <Button
                  onClick={() =>
                    getPDFProject({
                      project: { id: invoice.customerData?.number },
                      startDate: invoice.deliveryDateFrom,
                      endDate: invoice.deliveryDate,
                      invoice: invoice.number,
                    })
                  }
                  color="info"
                  variant="contained"
                  sx={{
                    minHeight: 44,
                  }}
                  disabled={!invoice?.timeSpent > 0}
                >
                  Zeitnachweis erstellen
                </Button>
              </FlexBox>
            </FlexBetween>
          </Card>
          <Card
            sx={{
              p: 0,
              mb: "30px",
            }}
          >
            <Grid container>
              <Grid xs={6} item sx={{ padding: "16px" }}>
                <Typography variant="caption">Empfänger</Typography>
                <Typography>{invoice.customerData?.name}</Typography>
              </Grid>
              <Grid xs={6} item sx={{ padding: "16px" }}>
                <Typography variant="caption">Datum</Typography>
                <Typography>
                  {isValid(parseISO(invoice.date))
                    ? format(parseISO(invoice.date), "dd.MM.yyyy")
                    : ""}
                </Typography>
              </Grid>
              <Grid xs={6} item sx={{ padding: "16px" }}>
                <Typography variant="caption">Status</Typography>
                <Typography>{invoice.state}</Typography>
              </Grid>
              <Grid xs={6} item sx={{ padding: "16px" }}>
                <Typography variant="caption">Betrag</Typography>
                <Typography>
                  {invoice.totalNet?.toLocaleString(undefined, {
                    minimumFractionDigits: 2,
                  })}
                  €
                </Typography>
              </Grid>
              <Grid xs={6} item sx={{ padding: "16px" }}>
                <Typography variant="caption">Von</Typography>
                <Typography>
                  {isValid(parseISO(invoice.deliveryDateFrom))
                    ? format(parseISO(invoice.deliveryDateFrom), "dd.MM.yyyy")
                    : ""}
                </Typography>
              </Grid>
              <Grid xs={6} item sx={{ padding: "16px" }}>
                <Typography variant="caption">Bis</Typography>
                <Typography>
                  {isValid(parseISO(invoice.deliveryDate))
                    ? format(parseISO(invoice.deliveryDate), "dd.MM.yyyy")
                    : ""}
                </Typography>
              </Grid>
              <Grid xs={6} item sx={{ padding: "16px" }}>
                <Typography variant="caption">Anz. Stunden</Typography>
                <Typography>{invoice.timeSpent / 3600}</Typography>
              </Grid>
              <Grid xs={6} item sx={{ padding: "16px" }}>
                <Typography variant="caption">Tage vor Ort</Typography>
                <Typography>{invoice.onSite?.length}</Typography>
              </Grid>
            </Grid>
          </Card>
          <Card
            sx={{
              p: 0,
              mb: "15px",
            }}
          >
            {invoice.positions?.length > 0
              ? invoice.positions.map((position) => {
                  return (
                    <Grid
                      container
                      sx={{ padding: "16px", borderBottom: "1px solid grey" }}
                    >
                      <Grid xs={12} item sx={{ padding: "2px" }}>
                        <Typography>{position.title}</Typography>
                      </Grid>
                      <Grid xs={3} item sx={{ padding: "2px" }}>
                        <Typography variant="caption">
                          Anzahl {position.unit}
                        </Typography>
                        <Typography>{position.amount}</Typography>
                      </Grid>
                      <Grid xs={3} item sx={{ padding: "2px" }}>
                        <Typography variant="caption">
                          Preis je {position.unit}
                        </Typography>
                        <Typography>{position.priceNet?.toLocaleString(
                              undefined,
                              { minimumFractionDigits: 2 }
                            )}€</Typography>
                      </Grid>
                      <Grid xs={3} item sx={{ padding: "2px" }}>
                        <Typography variant="caption">
                          Rabatt
                        </Typography>
                        <Typography>{position.discountPercent}%</Typography>
                      </Grid>
                      <Grid xs={3} item sx={{ padding: "2px" }}>
                        <Typography variant="caption">
                          Gesamt
                        </Typography>
                        <Typography>{position.totalNetAfterDiscount?.toLocaleString(
                              undefined,
                              { minimumFractionDigits: 2 }
                            )}€</Typography>
                      </Grid>
                      <Grid xs={12} item sx={{ padding: "2px" }}>
                        <Typography>
                          {position.description
                            .replaceAll("<div>", "")
                            .replaceAll("</div>", "")}
                        </Typography>
                      </Grid>
                    </Grid>
                  );
                })
              : "Keine Position erfasst"}
          </Card>
        </Grid>
      </Grid>
    </Container>
  );
};

export default InvoiceDetails;
