import "./css/App.css";
import { styled } from "@mui/material/styles";
import React, { Fragment, Suspense, useState } from "react";
import { CssBaseline } from "@mui/material";
import { Routes, Route } from "react-router-dom";
import { authenticationService } from "./auth/authentication.service";
import {
  useAlertMessageStore,
  useLoadingStore,
  useUserStore,
} from "./store/store";
import Container from "@mui/material/Container";

import AlertMessage from "components/AlertMessage";
import { Navbar } from "components/navbar";
import { PrivateWrapper } from "components/PrivateWrapper";
import Loading from "components/Loading";

import AdministrationAccount from "pages/Administration/Account";
import Home from "pages/Home";
import TimesheetUser from "pages/Timesheet/User";
import TimesheetProject from "pages/Timesheet/Project";
import JiraImport from "pages/Jira/Tasks/Import";
import Login from "pages/Login";
import { Header } from "components/header";
import ManageTasks from "pages/Jira/Tasks/Manage";
import CreateTask from "pages/Jira/Tasks/Manage/createTask";
import AdministrationProjects from "pages/Administration/Projects";
import ProjectDetails from "pages/Administration/Projects/projectDetails";
import CreateProject from "pages/Administration/Projects/createProject";
import TaskDetails from "pages/Jira/Tasks/Manage/taskDetails";
import { InvoiceDetails } from "pages/Invoice";

export default function App() {
  const [isLoading, setLoading] = useState(
    useLoadingStore.getState().isLoading
  );

  const [alertMessages, setAlertMessage] = useState(
    useAlertMessageStore.getState().alertMessage
  );

  const [currentUser, setCurrentUser] = useState(
    useUserStore.getState().currentUser
  );

  const subLoadingStore = useLoadingStore.subscribe((loadingStore) => {
    setLoading(loadingStore.isLoading);
  });

  const subAlertMessageStore = useAlertMessageStore.subscribe(
    (alertMessage) => {
      setAlertMessage(alertMessage.alertMessage);
    }
  );

  const subUserStore = useUserStore.subscribe((userStore) => {
    setCurrentUser(userStore.currentUser);
  });

  return (
    <Fragment>
      <Suspense fallback={<Loading />}>
        {alertMessages && (
          <AlertMessage
            message={alertMessages.message}
            severity={alertMessages.severity}
          />
        )}
        {isLoading && <Loading />}
        {currentUser && (
          <>
            <Header />
            <Navbar />
            <Container maxWidth="xxl" sx={{ height: "100vh" }}>
              <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/projects/:projectId" element={<Home />} />
                <Route
                  path="/invoices/:invoiceId"
                  element={<InvoiceDetails />}
                />
                <Route path="/timesheet/user" element={<TimesheetUser />} />
                <Route
                  path="/timesheet/project"
                  element={<TimesheetProject />}
                />
                <Route path="/jira/import" element={<JiraImport />} />
                <Route path="/jira/tasks" element={<ManageTasks />} />
                <Route path="/jira/tasks/create" element={<CreateTask />} />
                <Route path="/jira/tasks/:id" element={<TaskDetails />} />
                <Route
                  path="/administration/projects"
                  element={<AdministrationProjects />}
                />
                <Route
                  path="/administration/projects/:key"
                  element={<ProjectDetails />}
                />
                <Route
                  path="/administration/projects/create"
                  element={<CreateProject />}
                />
                <Route
                  path="/administration/account"
                  element={<AdministrationAccount />}
                />
              </Routes>
            </Container>
          </>
        )}
        {!currentUser && (
          <>
            <Routes>
              <Route path="/login" element={<Login />} />
              <Route path="*" element={<Login />} />
            </Routes>
          </>
        )}
      </Suspense>
    </Fragment>
  );
}
